import { Dialog } from '@headlessui/react';
import { FolderArrowDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Link, useNavigate } from '@remix-run/react';
import type { FunctionComponent } from 'react';
import { ringClass } from '~/styles/constants';
import { EmbeddedPdf } from './EmbeddedPdf';

export interface FilePreviewProps {
  file: {
    id: string;
    type: string;
    name: string;
  };
}

export const FilePreview: FunctionComponent<FilePreviewProps> = ({ file }) => {
  const navigate = useNavigate();

  return (
    <Dialog
      open={true}
      onClose={() => {
        navigate('?');
      }}
    >
      <Dialog.Panel className='fixed z-50 inset-0 bg-white/50 text-slate-700 backdrop-blur-lg flex flex-col'>
        <div className='flex z-20 items-center bg-white shadow px-6 py-4 justify-between'>
          <span>{file.name}</span>
          <Link className={`ml-4 p-2 rounded-md hover:bg-slate-100 ${ringClass}`} to={`?`}>
            <XMarkIcon className='h-5 w-5' />
          </Link>
        </div>

        <div className='flex-1 overflow-y-auto flex flex-col'>
          {file.type.startsWith('image/') ? (
            <div className={'flex h-full items-center justify-center'}>
              <img src={`/descargar/${file.id}`} alt={file.name} height={500} width={600} className='object-contain' />
            </div>
          ) : (
            file.type.startsWith('application/pdf') && <EmbeddedPdf file={file} />
          )}
        </div>

        <a
          title={`Descargar ${file.name}`}
          href={`/descargar/${file.id}`}
          className={`flex z-20 bg-white absolute bottom-10 left-10 items-center gap-2 shadow-lg px-6 py-2 rounded-md hover:bg-blue-100 transition-all ${ringClass}`}
          download
        >
          <FolderArrowDownIcon className='h-5 w-5 text-blue-500' />
          <span>Descargar</span>
        </a>
      </Dialog.Panel>
    </Dialog>
  );
};
