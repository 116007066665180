import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ringClass } from '~/styles/constants';
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const EmbeddedPdf = ({ file }: { file: { id: string; name?: string } }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess(params: { numPages: number }) {
    setNumPages(params.numPages);
  }

  return (
    <div className={'mx-auto flex-1 max-w-4xl w-full flex flex-col items-center py-20 px-3'}>
      <div className='flex justify-between items-center gap-4 self-stretch'>
        <button
          type='button'
          aria-label='Página anterior'
          className={`p-2 bg-white rounded-md border shadow disabled:opacity-50 ${ringClass}`}
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          <ChevronLeftIcon className='h-5 w-5' />
        </button>
        <p className={numPages ? 'visible' : 'invisible'}>
          Página {pageNumber} de {numPages}
        </p>
        <button
          type='button'
          aria-label='Siguiente página'
          className={`p-2 bg-white rounded-md border shadow disabled:opacity-50 ${ringClass}`}
          disabled={numPages ? pageNumber >= numPages : false}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          <ChevronRightIcon className='h-5 w-5' />
        </button>
      </div>

      <div className={'mt-8 p-10 flex-1 flex items-center'}>
        <Document
          file={`/descargar/${file.id}`}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          // TODO: fix error message appearing on first load
          error={<div>Cargando...</div>}
          loading={<div>Cargando...</div>}
        >
          <Page
            width={600}
            className='shadow-xl'
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            error={<div>Ha ocurrido un error al cargar esta página</div>}
            loading={<div>Cargando...</div>}
          />
        </Document>
      </div>
    </div>
  );
};
