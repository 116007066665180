import { Bars3Icon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid';
import type { Page } from '@prisma/client';
import { Form, Link, NavLink, useParams } from '@remix-run/react';
import type { SerializeFrom } from '@remix-run/server-runtime';
import { useEffect, useState } from 'react';

import { useOptionalUser } from '~/utils';
import { Button } from './Button';

export default function Nav({ pages }: { pages: SerializeFrom<Page[]> }) {
  const { user } = useOptionalUser();
  const [open, setOpen] = useState(false);
  const params = useParams();

  useEffect(() => {
    setOpen(false);
  }, [params]);
  return (
    <nav className={'flex flex-col bg-white'}>
      <div className='flex flex-wrap items-center gap-4 border-b p-4'>
        <div className='flex items-center gap-4'>
          <a href='https://www.gob.mx/' target='_blank' rel='noopener noreferrer'>
            <img src='/images/gob.png' alt='Logo' className={'mr-4 h-8 object-contain'} />
          </a>
          <a href='https://www.tecnm.mx/' target='_blank' rel='noopener noreferrer'>
            <img src='/images/tecnm.png' alt='Logo' className={'mr-4 h-8 object-contain'} />
          </a>
          <a href='http://saltillo.tecnm.mx' target='_blank' rel='noopener noreferrer'>
            <img src='/images/its.png' alt='Logo' className={'mr-4 h-8 object-contain'} />
          </a>
        </div>

        <header className={'font-semibold hidden sm:block'}>Coordinación de Sistemas de Gestión</header>
        <header className={'font-semibold sm:hidden'}>CGS</header>
        <button className='ml-auto block sm:hidden' onClick={() => setOpen(!open)}>
          {!open ? <Bars3Icon className='h-5 w-5' /> : <XMarkIcon className='h-5 w-5' />}
        </button>
        {user ? (
          <ul className={'ml-auto hidden sm:flex items-center gap-4'}>
            <li>
              <span id='user-email' className={'sm:block hidden'}>
                {user.email}
              </span>
            </li>
            <li>
              <Form action='/logout' method='post'>
                <Button icon={ArrowRightOnRectangleIcon} appearance={'secondary'}>
                  Salir
                </Button>
              </Form>
            </li>
          </ul>
        ) : (
          <ul className={'ml-auto flex items-center gap-4'}>
            <li>
              <Link to='/login'>
                <Button icon={ArrowLeftOnRectangleIcon} appearance={'secondary'}>
                  Entrar
                </Button>
              </Link>
            </li>
          </ul>
        )}
      </div>

      <ul className={`${open ? 'flex' : 'hidden sm:flex'} ml-4 sm:flex-row flex-col gap-2 items-center border-b`}>
        <li className={'flex'}>
          <NavLink
            to='/'
            className={({ isActive }) => `px-7 py-3 font-medium ${isActive ? 'text-slate-800' : 'text-slate-500'}`}
          >
            Inicio
          </NavLink>
        </li>
        <li className={'flex'}>
          <NavLink
            to='/procesos'
            className={({ isActive }) => `px-7 py-3 font-medium ${isActive ? 'text-slate-800' : 'text-slate-500'}`}
          >
            Calidad
          </NavLink>
        </li>
        {pages.map(p => (
          <li key={p.identifier} className={'flex'}>
            <NavLink
              to={`/${p.identifier}`}
              className={({ isActive }) => `px-7 py-3 font-medium ${isActive ? 'text-slate-800' : 'text-slate-500'}`}
            >
              {p.name}
            </NavLink>
          </li>
        ))}
        {user?.isEditor && (
          <li className={'flex'}>
            <NavLink
              to='/nuevo'
              className={({ isActive }) =>
                `px-7 py-3 font-medium flex gap-2 items-center  ${isActive ? 'text-slate-800' : 'text-blue-700'}`
              }
            >
              <span>Nuevo sistema</span>
              <PlusIcon className='h-5 w-5' />
            </NavLink>
          </li>
        )}
        {user && (
          <ul className={'w-full sm:hidden flex flex-col items-center gap-2 my-4'}>
            <li>
              <span id='user-email'>{user.email}</span>
            </li>
            <li>
              <Form action='/logout' method='post'>
                <Button icon={ArrowRightOnRectangleIcon} appearance={'secondary'}>
                  Salir
                </Button>
              </Form>
            </li>
          </ul>
        )}
      </ul>
    </nav>
  );
}
